import React from 'react';
import { Redirect } from 'react-router';
import {API_URL} from '../Redux-service/api/api';
import { createBrowserHistory } from "history";
import {persistor,store} from '../Redux-service/store/create.store';
import toaster from "./ReactTostify";
import { admin_purge } from '../Redux-service/actions/action.users/action.function';
import { useDispatch } from 'react-redux'
import { isLoading } from '../Redux-service/actions/action.loader/action.set.type';


const history = createBrowserHistory();





const errorPage = (error,url ) =>{
  // const { status, data = {} } = error.response;
  // const { session = "", message = "" } = data;
  // const dispatch = useDispatch();
const { status } = error.response;
    switch (status) {
      case 401:
       
          localStorage.setItem("token",'');
          localStorage.setItem("user",null);
          localStorage.setItem("isLoggedIn",false);
          isLoading(false);
      //  persistor.purge();
       localStorage.removeItem('token');
       localStorage.removeItem('auth');
       
// sessionStorage.removeItem('token');
// sessionStorage.removeItem('auth');
// localStorage.clear();
  // store.dispatch({
  //       type: 'ADMIN_PURGE_REQUEST'
  //     })
  persistor.pause();
  persistor.flush().then(() => {
    return persistor.purge();
  });
    localStorage.clear();
  isLoading(false);
           history.push("/login");
           window.location.reload();
          //  isLoading(false);
           toaster('error', "You have been logged out,kindly login again"); 
      
        break;
      case 400:
        isLoading(false);
        toaster('error', "Bad request");
           break;
      
      case 403:
        isLoading(false);
        toaster('error', "Forbidden");
        break;
      case 500:
        isLoading(false);
           history.push("/500");
           window.location.reload();
           toaster('error', "Opps!!! something went wrong");
           isLoading(false);
        break;
        case 429:
          isLoading(false);
             history.push("/dashboard");
             window.location.reload();
             toaster('error', "To many request");
             isLoading(false);
          break;
      case 404:
        // history.push("/#/login");
        window.location.reload();
        isLoading(false);
          toaster('error', "Page not found");
          // history.push("/404");
           window.location.reload();
           isLoading(false);
          break;
          case 412:
            isLoading(false);
          toaster('error', error.response.data.message);
           break;
           case 422:
            isLoading(false);
          toaster('error', error.response.data.message);
           break;
      
      default:
        return "";
    }




















//   if(status === 400){
//     return (
//         <Redirect to={{
//           pathname: "/400"
//         }} />
//       )
//   }
  
//   if(status === 401){
//     return (
//         <Redirect to={{
//           pathname: "/400"
//         }} />
//       )

// }
// if(status === 404){
//     return (
//         <Redirect to={{
//           pathname: "/404"
//         }} />
//       )

// }
// if(status === 500){
//     return (
//         <Redirect to={{
//           pathname: "/500"
//         }} />
//       )

// }


}

export default errorPage;