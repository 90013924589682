/** write your api's here and export to respective actions */
import { config_file } from "../../utils/env_config";

//export const API_URL_ROOT ="http://127.0.0.1:4000"
export const API_URL_ROOT = config_file[process.env["NODE_ENV"]].API_DOMAIN_NAME;
export const GOOGLE_MAP_API_KEY = config_file[process.env["NODE_ENV"]].GOOGLE_MAP_API_KEY;
export const API_URL ={
  LOGIN:`${API_URL_ROOT}/auth/login`,

  // ADMIN
  GET_ALL_ADMIN:`${API_URL_ROOT}/auth/getmanager`,
  CREATE_ADMIN:`${API_URL_ROOT}/auth/addmanager`,
  GET_ADMIN_BY_ID:`${API_URL_ROOT}/auth/viewmanager`,
  ENABLE_DISABLE_ADMIN:`${API_URL_ROOT}/auth/enabledisblemanager`,
  UPDATE_ADMIN_DETAILS:`${API_URL_ROOT}/auth/editmanager`,

  // technician
  GET_ALL_TECHNICIAN:`${API_URL_ROOT}/auth/gettechnician`,
  CREATE_TECHNICIAN:`${API_URL_ROOT}/auth/addtechnician`,
  GET_TECHNICIAN_BY_ID:`${API_URL_ROOT}/auth/viewtechnician`,
  ENABLE_DISABLE_TECHNICIAN:`${API_URL_ROOT}/auth/enabledisbletechnician`,
  UPDATE_TECHNICIAN_DETAILS:`${API_URL_ROOT}/auth/edittechnician`,
  GET_TECHNICIAN_REPORTS_BY_TECHNICIAN_ID:`${API_URL_ROOT}/auth/technicianReports`,
  GET_TECHNICIAN_REPORTS_BY_ORDER_ID:`${API_URL_ROOT}/auth/getTechnicianReportsByOrderid`,
  //area
  GET_ALL_AREA:`${API_URL_ROOT}/auth/getarea`,
  CREATE_AREA:`${API_URL_ROOT}/auth/addarea`,
  GET_AREA_BY_ID:`${API_URL_ROOT}/auth/viewarea`,
  ENABLE_DISABLE_AREA:`${API_URL_ROOT}/auth/enabledisblearea`,
  UPDATE_AREA_DETAILS:`${API_URL_ROOT}/auth/editarea`,
  GET_CITY_AREA_BY_ID:`${API_URL_ROOT}/auth/getareaincity`,

  // city
  GET_ALL_CITY:`${API_URL_ROOT}/auth/getcity`,
  CREATE_CITY:`${API_URL_ROOT}/auth/addcity`,
  GET_CITY_BY_ID:`${API_URL_ROOT}/auth/viewcity`,
  ENABLE_DISABLE_CITY:`${API_URL_ROOT}/auth/enabledisblecity`,
  UPDATE_CITY_DETAILS:`${API_URL_ROOT}/auth/editcity`,

  // category
  GET_ALL_CATEGORY:`${API_URL_ROOT}/auth/getcategory`,
  CREATE_CATEGORY:`${API_URL_ROOT}/auth/addcategory`,
  GET_CATEGORY_BY_ID:`${API_URL_ROOT}/auth/viewcategory`,
  ENABLE_DISABLE_CATEGORY: `${API_URL_ROOT}/auth/enabledisblecategory`,
  UPDATE_CATEGORY_DETAILS:`${API_URL_ROOT}/auth/editcategory`,
   // service

 GET_ALL_SERVICE: `${API_URL_ROOT}/auth/getservice`,
 CREATE_SERVICE :`${API_URL_ROOT}/auth/addservice`,
 GET_SERVICE_BY_ID :`${API_URL_ROOT}/auth/viewservice`,
 ENABLE_DISABLE_SERVICE : `${API_URL_ROOT}/auth/enabledisbleservice`,
 UPDATE_SERVICE_DETAILS : `${API_URL_ROOT}/auth/editservice`,

// packages

 GET_ALL_PACKAGE : `${API_URL_ROOT}/auth/getpackage`,
 CREATE_PACKAGE : `${API_URL_ROOT}/auth/addpackage`,
 GET_PACKAGE_BY_ID : `${API_URL_ROOT}/auth/viewpackage`,
 ENABLE_DISABLE_PACKAGE : `${API_URL_ROOT}/auth/enabledisblepackage`,
 UPDATE_PACKAGE_DETAILS : `${API_URL_ROOT}/auth/editpackage`,

// time slots

 GET_ALL_TIMESLOT : `${API_URL_ROOT}/auth/gettimeslot`,
 CREATE_TIMESLOT : `${API_URL_ROOT}/auth/addtimeslot`,
 GET_TIMESLOT_BY_ID : `${API_URL_ROOT}/auth/viewtimeslot`,
 ENABLE_DISABLE_TIMESLOT : `${API_URL_ROOT}/auth/enabledisbletimeslot`,
 UPDATE_TIMESLOT_DETAILS : `${API_URL_ROOT}/auth/edittimeslot`,

// discount coupons

 GET_ALL_DISCOUNTCOUPON : `${API_URL_ROOT}/auth/getcoupon`,
 CREATE_DISCOUNTCOUPON : `${API_URL_ROOT}/auth/addcoupon`,
 GET_DISCOUNTCOUPON_BY_ID : `${API_URL_ROOT}/auth/viewcoupon`,
 ENABLE_DISABLE_DISCOUNTCOUPON : `${API_URL_ROOT}/auth/enabledisblecoupon`,
 UPDATE_DISCOUNTCOUPON_DETAILS : `${API_URL_ROOT}/auth/editcoupon`,


 // faq

 GET_ALL_FAQ : `${API_URL_ROOT}/auth/getfaq`,
 CREATE_FAQ : `${API_URL_ROOT}/auth/addfaq`,
  GET_FAQ_BY_ID  : `${API_URL_ROOT}/auth/viewfaq`,
  ENABLE_DISABLE_FAQ : `${API_URL_ROOT}/auth/enabledisblefaq`,
  UPDATE_FAQ_DETAILS :`${API_URL_ROOT}/auth/editfaq`,

 // feedback

 GET_ALL_FEEDBACK : `${API_URL_ROOT}/auth/getfeedback`,
 CREATE_FEEDBACK : `${API_URL_ROOT}/auth/feedbackquestion`,
  GET_FEEDBACK_BY_ID : `${API_URL_ROOT}/auth/viewfeedback`,
 ENABLE_DISABLE_FEEDBACK : `${API_URL_ROOT}/auth/enabledisblefeedback`,
  UPDATE_FEEDBACK_DETAILS : `${API_URL_ROOT}/auth/editfeedback`,
  // user rating feedback
  GET_ALL_USER_RATING_FEEDBACK : `${API_URL_ROOT}/auth/fetchAllAverageUserRatingFeedback`,
  GET_ALL_USER_RATING_FEEDBACK_BY_ORDER_ID : `${API_URL_ROOT}/auth/getAverageUserRatingFeedbackByOrderId`,


//  Article

 GET_ALL_ARTICLE : `${API_URL_ROOT}/auth/getarticle`,
 CREATE_ARTICLE : `${API_URL_ROOT}/auth/addarticle`,
 GET_ARTICLE_BY_ID : `${API_URL_ROOT}/auth/viewarticle`,
 ENABLE_DISABLE_ARTICLE :`${API_URL_ROOT}/auth/enabledisblearticle`,
 UPDATE_ARTICLE_DETAILS :`${API_URL_ROOT}/auth/editarticle`,


// Miscellaneous

// perKilometerCharge

 GET_PERKILOMETERCHARGE : `${API_URL_ROOT}/auth/getperkilometercharges`,
 CREATE_PERKILOMETERCHARGE : `${API_URL_ROOT}/auth/addperkilometercharges`,
  UPDATE_PERKILOMETERCHARGE_DETAILS : `${API_URL_ROOT}/auth/editperkilometercharges`,


// penaltyAmountCancellingOrder

 GET_PENALTYAMOUNTCANCELLINGORDER : `${API_URL_ROOT}/auth/getcancelationpenalty`,
 CREATE_PENALTYAMOUNTCANCELLINGORDER : `${API_URL_ROOT}/auth/addcancelationpenalty`,
  UPDATE_PENALTYAMOUNTCANCELLINGORDER_DETAILS : `${API_URL_ROOT}/auth/editcancelationpenalty`,

 // minimumAmountPlacingOrder

 GET_MINIMUMAMOUNTPLACINGORDER : `${API_URL_ROOT}/auth/getminimumamountforplacingorder`,
 CREATE_MINIMUMAMOUNTPLACINGORDER : `${API_URL_ROOT}/auth/addminimumamountforplacingorder`,
  UPDATE_MINIMUMAMOUNTPLACINGORDER_DETAILS : `${API_URL_ROOT}/auth/editminimumamountforplacingorder`,

 // lateCancellationPenalty 

 GET_LATECANCELLATIONPENALTY  : `${API_URL_ROOT}/auth/getlatecancelationpenalty`,
 CREATE_LATECANCELLATIONPENALTY  : `${API_URL_ROOT}/auth/addlatecancelationpenalty`,
  UPDATE_LATECANCELLATIONPENALTY_DETAILS : `${API_URL_ROOT}/auth/editlatecancelationpenalty`,

 // emergencyRequestCharge

 GET_EMERGENCYREQUESTCHARGE : `${API_URL_ROOT}/auth/getemergencyrequestcharges`,
 CREATE_EMERGENCYREQUESTCHARGE : `${API_URL_ROOT}/auth/addemergencyrequestcharges`,
 UPDATE_EMERGENCYREQUESTCHARGE_DETAILS : `${API_URL_ROOT}/auth/editemergencyrequestcharges`,

 // codHardCopyReports

 GET_CODHARDCOPYREPORTS : `${API_URL_ROOT}/auth/getcostfordoordelivery`,
 CREATE_CODHARDCOPYREPORTS : `${API_URL_ROOT}/auth/addcostfordoordelivery`,
 UPDATE_CODHARDCOPYREPORTS_DETAILS : `${API_URL_ROOT}/auth/editcostfordoordelivery`,


 // customerDevliveryCharges

 GET_CUSTOMERDELIVERYCHARGES : `${API_URL_ROOT}/auth/getcustomerdeliverycharge`,
 CREATE_CUSTOMERDELIVERYCHARGES : `${API_URL_ROOT}/auth/addcustomerdeliverycharge`,
 UPDATE_CUSTOMERDELIVERYCHARGES_DETAILS : `${API_URL_ROOT}/auth/editcustomerdeliverycharge`,

 // Orders api 
 GET_ALL_ORDERS :`${API_URL_ROOT}/auth/getorder`,
 GET_ORDER_BY_ORDER_ID:`${API_URL_ROOT}/auth/getOrdersByOrderID`,
ASSIGN_TECHNICIAN_BY_ORDER_ID:`${API_URL_ROOT}/auth/createAssignOrderToTechnician`,
GET_ASSIGN_TECHNICIAN_BY_ORDER_ID:`${API_URL_ROOT}/auth/getAssignOrderTechnicianByOrderId`,
ORDER_STATUS_UPDATE:`${API_URL_ROOT}/order/orderstatusupdate`,
UPDATE_ASSIGN_TECHNICIAN_BY_ORDER_ID:`${API_URL_ROOT}/auth/assignTechnicianByOrderId`,
CANCEL_ORDER_BY_ORDER_ID:`${API_URL_ROOT}/auth/cancelOrder`,
GET_ALL_REFUNDABLE_ORDERS:`${API_URL_ROOT}/auth/getCancelOrderOnlinePayment`,
POST_REFUNDABLE_ORDER_BY_ORDER_ID:`${API_URL_ROOT}/payment/refund`,
  
// Reschedule_Orders api 
Reschedule_Order: `${API_URL_ROOT}/auth/reScheduleOrder`,

 //user view api
 GET_ALL_USER_VIEW:`${API_URL_ROOT}/auth/getuser`,
 GET_USER_VIEW_BY_ID:`${API_URL_ROOT}/auth/getuserByUserID`,
 ENABLE_DISABLE_USER_VIEW_BY_ID:`${API_URL_ROOT}/auth/enabledisbleuser`,

 GET_ALL_ORDERS_DETAILS_BY_USER_ID:`${API_URL_ROOT}/auth/getalldetailsbyuserid`,
 GET_USER_VIEW_BY_USER_ID:`${API_URL_ROOT}/auth/viewuser`,
 UPDATE_USER_VIEW_BY_USER_ID:`${API_URL_ROOT}/auth/edituser`,



  //Family members api
  GET_ALL_FAMILY_MEMBERS:`${API_URL_ROOT}/auth/getpatient`,
  GET_FAMILYMEMBER_BY_ID:`${API_URL_ROOT}/auth/viewpatient`,
  CREATE_FAMILY_MEMBERS_MODAL:`${API_URL_ROOT}/auth/addfamilymember`,
  EDIT_FAMILYMEMBER:`${API_URL_ROOT}/auth/editfamilymember`,

  CREATE_FAMILY_MEMBERS_ADDRESS:`${API_URL_ROOT}/auth/addpatientaddress`,
  GET_ALL_FAMILY_MEMBERS_ADDRESS:`${API_URL_ROOT}/auth/getpatientaddress`,
 
  // Notifications
  // Push-Notifications
  CREATE_PUSH_NOTIFICATIONS:`${API_URL_ROOT}/notification/createpushnotification`,
  GET_ALL_PUSH_NOTIFICATIONS:`${API_URL_ROOT}/notification/getpushnotification`,
  SEND_PUSH_NOTIFICATIONS:`${API_URL_ROOT}/notification/notifications`,
  ENABLE_DISABLE_PUSH_NOTIFICATIONS:`${API_URL_ROOT}/`,

  // Call back request api
  GET_ALL_BOOK_ON_CALL:`${API_URL_ROOT}/auth/getallbookoncall`,
  GET_BOOK_ON_CALL_BY_BOOK_ON_CALL_ID:`${API_URL_ROOT}/auth/getallbookoncallbybookoncallid`,
  CREATE_ORDER_FROM_CALL_BACK_REQUEST:`${API_URL_ROOT}/auth/createOrder`,
  UPDATE_BOOK_ON_CALL_BY_ID_STATUS_AND_REMARKS:`${API_URL_ROOT}/auth/callbackrequestupdate`,

  // Support apis
  GET_ALL_SUPPORT:`${API_URL_ROOT}/auth/getAllSupport`,
  GET_SUPPORT_BY_TICKET_ID:`${API_URL_ROOT}/auth/getSupportByTicketId`,
  UPDATE_SUPPORT_STATUS_BY_TICKET_ID:`${API_URL_ROOT}/auth/updateSupportStatus`,
  CREATE_SUPPORT_CONVERSATION_BY_TICKET_ID:`${API_URL_ROOT}/auth/createconversation`,
  GET_SUPPORT_CONVERSATION_BY_TICKET_ID:`${API_URL_ROOT}/auth/getallconversation`,
  GET_DETAILED_RATINGS_OF_QUESTION_BY_FEEDBACK_ID:`${API_URL_ROOT}/auth/getDetailedRatingsOfQuestionByFeedbackId`,
  TICKET_STATUS_UPDATE:`${API_URL_ROOT}/auth/updateSupportStatus`,

// Dashboard data 
GET_DASHBOARD_DATA:`${API_URL_ROOT}/auth/fetchalldashboarddetails`,

   //logout 
   VERIFY_LOGOUT:`${API_URL_ROOT}/auth/logout`,

//order_bargraph
 ALL_ORDER_VALUE :`${API_URL_ROOT}/order/getOrderStatusByDate`,
}

export const SUCCESS = "success";
export const ERROR = "error";
export const REQUESTING = "requesting";
export const FAIL = "fail";


// login module 
export const login_user = `${API_URL_ROOT}/auth/login`;
export const logout_user = "/auth/logout";

// admin

export const get_all_admin = "/auth/getmanager";
export const create_admin = "/auth/addmanager";
 export const get_admin_by_id ="/auth/viewmanager";
 export const enable_disable_admin = `/auth/enabledisblemanager`;
//export const enable_disable_admin = (admin_id) => `/auth/enabledisblemanager/${admin_id}`;
 export const update_admin_details = "/auth/editmanager";


 // technician

export const get_all_technician = "/auth/gettechnician";
export const create_technician = "/auth/addtechnician";
 export const get_technician_by_id ="/auth/viewtechnician";
 export const enable_disable_technician = `/auth/enabledisbletechnician`;
//export const enable_disable_technician = (technician_id) => `/auth/enabledisbletechnician/${technician_id}`;
 export const update_technician_details = "/auth/edittechnician";

  // area

export const get_all_area = "/auth/getarea";
export const create_area = "/auth/addarea";
 export const get_area_by_id ="/auth/viewarea";
 export const enable_disable_area = `/auth/enabledisblearea`;
//export const enable_disable_area = (area_id) => `/auth/enabledisblearea/${area_id}`;
 export const update_area_details = "/auth/editarea";

 export const get_city_area_by_id = "/auth/getareaincity";

  // city

export const get_all_city = "/auth/getcity";
export const create_city = "/auth/addcity";
 export const get_city_by_id ="/auth/viewcity";
 export const enable_disable_city = `/auth/enabledisblecity`;
//export const enable_disable_city = (city_id) => `/auth/enabledisblecity/${city_id}`;
 export const update_city_details = "/auth/editcity";


   // category

export const get_all_category = "/auth/getcategory";
export const create_category = "/auth/addcategory";
 export const get_category_by_id ="/auth/viewcategory";
 export const enable_disable_category = `/auth/enabledisblecategory`;
//export const enable_disable_category = (category_id) => `/auth/enabledisblecategory/${category_id}`;
 export const update_category_details = "/auth/editcategory";


 
   // service

export const get_all_service = "/auth/getservice";
export const create_service = "/auth/addservice";
 export const get_service_by_id ="/auth/viewservice";
 export const enable_disable_service = `/auth/enabledisbleservice`;
//export const enable_disable_service = (service_id) => `/auth/enabledisbleservice/${service_id}`;
 export const update_service_details = "/auth/editservice";

// packages

export const get_all_package = "/auth/getpackage";
export const create_package = "/auth/addpackage";
export const get_package_by_id = "/auth/viewpackage";
export const enable_disable_package = "/auth/enabledisblepackage";
export const update_package_details = "/auth/editpackage"

// time slots

export const get_all_timeslot = "/auth/gettimeslot";
export const create_timeslot = "/auth/addtimeslot";
export const get_timeslot_by_id = "/auth/viewtimeslot";
export const enable_disable_timeslot = "/auth/enabledisbletimeslot";
export const update_timeslot_details = "/auth/edittimeslot";

// discount coupons

export const get_all_discountcoupon = "/auth/getcoupon";
export const create_discountcoupon = "/auth/addcoupon";
export const get_discountcoupon_by_id = "/auth/viewcoupon";
export const enable_disable_discountcoupon = "/auth/enabledisblecoupon";
export const update_discountcoupon_details = "/auth/editcoupon";


 // faq

export const get_all_faq = "/auth/getfaq";
export const create_faq = "/auth/addfaq";
 export const get_faq_by_id ="/auth/viewfaq";
 export const enable_disable_faq = `/auth/enabledisblefaq`;
//export const enable_disable_faq = (faq_id) => `/auth/enabledisblefaq/${faq_id}`;
 export const update_faq_details = "/auth/editfaq";

 // feedback

export const get_all_feedback = "/auth/getfeedback";
export const create_feedback = "/auth/feedbackquestion";
 export const get_feedback_by_id ="/auth/viewfeedback";
export const enable_disable_feedback = `/auth/enabledisblefeedback`;
//export const enable_disable_feedback = (feedback_id) => `/auth/enabledisblefeedback/${feedback_id}`;
 export const update_feedback_details = "/auth/editfeedback";


//  Article

export const get_all_article = "/auth/getarticle";
export const create_article = "/auth/addarticle";
export const get_article_by_id ="/auth/viewarticle";
export const enable_disable_article ="/auth/enabledisblearticle";
export const update_article_details ="/auth/editarticle";


// Miscellaneous

// perKilometerCharge

export const get_perKilometerCharge = "/auth/getperkilometercharges";
export const create_perKilometerCharge = "/auth/addperkilometercharges";
 export const update_perKilometerCharge_details = "/auth/editperkilometercharges";


// penaltyAmountCancellingOrder

export const get_penaltyAmountCancellingOrder = "/auth/getcancelationpenalty";
export const create_penaltyAmountCancellingOrder = "/auth/addcancelationpenalty";
 export const update_penaltyAmountCancellingOrder_details = "/auth/editcancelationpenalty";

 // minimumAmountPlacingOrder

export const get_minimumAmountPlacingOrder = "/auth/getminimumamountforplacingorder";
export const create_minimumAmountPlacingOrder = "/auth/addminimumamountforplacingorder";
 export const update_minimumAmountPlacingOrder_details = "/auth/editminimumamountforplacingorder";

 // lateCancellationPenalty 

export const get_lateCancellationPenalty  = "/auth/getlatecancelationpenalty";
export const create_lateCancellationPenalty  = "/auth/addlatecancelationpenalty";
 export const update_lateCancellationPenalty_details = "/auth/editlatecancelationpenalty";

 // emergencyRequestCharge

export const get_emergencyRequestCharge = "/auth/getemergencyrequestcharges";
export const create_emergencyRequestCharge = "/auth/addemergencyrequestcharges";
 export const update_emergencyRequestCharge_details = "/auth/editemergencyrequestcharges";

 // codHardCopyReports

export const get_codHardCopyReports = "/auth/getcostfordoordelivery";
export const create_codHardCopyReports = "/auth/addcostfordoordelivery";
 export const update_codHardCopyReports_details = "/auth/editcostfordoordelivery";